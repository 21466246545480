<template>
	<div class="connect-type">
		<h5 class="page-subtitle">Как с Вами связаться?</h5>
		<div class="options">
			<div
				class="telegram option-item"
				:class="{ checked: value === 'telegram' }"
				@click="onOptionInput('telegram')"
			>
				<div class="head">
					<div class="icon">
						<i class="fab fa-telegram" />
					</div>
					<label>
						Написать в Telegram
					</label>
				</div>
				<input
					type="radio"
					value="telegram"
					:checked="value === 'telegram'"
				/>
			</div>

			<div
				class="whatsapp option-item"
				:class="{ checked: value === 'whatsapp' }"
				@click="onOptionInput('whatsapp')"
			>
				<div class="head">
					<div class="icon">
						<i class="fab fa-whatsapp" />
					</div>
					<label>
						Написать в Whats App
					</label>
				</div>
				<input
					type="radio"
					value="whatsapp"
					:checked="value === 'whatsapp'"
				/>
			</div>

			<div
				class="viber option-item"
				:class="{ checked: value === 'viber' }"
				@click="onOptionInput('viber')"
			>
				<div class="head">
					<div class="icon">
						<i class="fab fa-viber" />
					</div>
					<label>
						Написать в Viber
					</label>
				</div>
				<input
					type="radio"
					value="viber"
					:checked="value === 'viber'"
				/>
			</div>

			<div
				class="call option-item"
				:class="{ checked: value === 'callme' }"
				@click="onOptionInput('callme')"
			>
				<div class="head">
					<div class="icon">
						<i class="fas fa-phone-volume" />
					</div>
					<label>
						Позвонить мне
					</label>
				</div>
				<input
					type="radio"
					value="callme"
					:checked="value === 'callme'"
				/>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'ContactOptions',
	props: {
		value: {
			type: String,
			required: false,
			default: 'whatsapp',
		},
	},
	methods: {
		onOptionInput(value) {
			this.$emit('input', value);
		},
	},
};
</script>

<style lang="less" scoped>
[type='radio'] {
	cursor: pointer !important;
	height: 30px;
	width: 30px;
}

[type='radio']:not(:checked),
[type='radio']:checked {
	position: relative !important;
	opacity: 1 !important;
	pointer-events: inherit !important;
}
.connect-type {
	position: relative;
	margin-top: 60px;
	width: 100%;

	.options {
		width: 100%;
		display: flex;
		justify-content: center;
		flex-direction: column;
		gap: 36px;
	}

	.option-item {
		cursor: pointer;
		transition: all 0.15s;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
		height: 45px;
		padding-right: 20px;
		padding: 8px;

		transition: transform 0.3s;
		.head {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			justify-content: flex-start;
			gap: 20px;
			.icon {
				top: 15px;
				font-size: 30px;
			}
			label {
				text-align: left;
				font-size: 18px;
				font-weight: 500;
				color: #343434;
				cursor: pointer;
			}
		}
	}

	.call {
		.title {
			top: 19px;
		}
		span {
			&:before {
				border: 2px solid #4e4e4e;
			}
			&:after {
				background-color: #696969;
			}
		}
		i {
			color: #696969;
		}
	}
	.telegram {
		span {
			&:before {
				border: 2px solid #57b3ff;
			}
			&:after {
				background-color: #57b3ff;
			}
		}
		i {
			color: #57b3ff;
		}
	}
	.whatsapp {
		span {
			&:before {
				border: 2px solid #00f37f;
			}
			&:after {
				background-color: #00f37f;
				border: none;
			}
		}
		i {
			color: #00f37f;
		}
	}
	.viber {
		span {
			&:before {
				border: 2px solid #8f5db7;
			}
			&:after {
				background-color: #8f5db7;
			}
		}
		i {
			color: #8f5db7;
		}
	}

	.checked {
		.icon {
			transition: transform 0.3s;
			transform: scale(1.3);
		}
	}
}
</style>
