<template>
    <section class="ship-type">
        <div class="page-subtitle center">Доставка или самовывоз?</div>
        <div class="layout">
            <div
                class="pickup card"
                :class="{ checked: value === 'pickup' }"
                @click="selectOption('pickup')"
            >
                <div class="head">
                    <input
                        value="pickup"
                        name="type"
                        type="radio"
                        :checked="value === 'pickup'"
                    />
                    <label> Самовывоз из г. Видное </label>
                </div>
                <div class="body">
                    <span>Бесплатно</span>
                    <h5 class="pin">&#128205;</h5>
                </div>
            </div>

            <div
                class="delivery card"
                :class="{ checked: value === 'delivery' }"
                @click="selectOption('delivery')"
            >
                <div class="head">
                    <input
                        value="delivery"
                        name="type"
                        type="radio"
                        :checked="value === 'delivery'"
                    />
                    <label> Доставка </label>
                </div>
                <div class="body">
                    <span> {{ deliveryPrice | currency }} </span>
                    <img
                        src="../../../public/img/backs/photo_2019-11-22_03-13-49.jpg"
                        class="responsive-img"
                        alt=""
                    />
                </div>
            </div>
        </div>
        <transition name="fade">
            <div
                v-show="value === 'delivery'"
                :class="{ checked: value === 'delivery' }"
                class="address col-12"
            >
                <h4>
                    <strong> Адрес доставки </strong>
                    (улица, дом, подъезд, домофон)
                </h4>

                <div class="delivery-to-address">
                    <div class="input-field text-area">
                        <textarea
                            @input="
                                $emit('update:address', $event.target.value)
                            "
                            id="address"
                        />

                        <label for="address"> Адресс доставки* </label>
                    </div>
                </div>
            </div>
        </transition>
    </section>
</template>
<script>
export default {
    name: "ShipOptions",
    props: {
        value: {
            type: String,
            required: false,
            default: "50",
        },
        address: {
            type: String,
            required: false,
        },
        deliveryPrice: {
            type: Number,
            required: true,
            default: 390,
        },
    },
    methods: {
        selectOption(value) {
            this.$emit("input", value);
        },
    },
};
</script>

<style lang="less" scoped>
[type="radio"] {
    cursor: pointer !important;
    height: 30px;
    width: 30px;
}

[type="radio"]:not(:checked),
[type="radio"]:checked {
    position: relative !important;
    opacity: 1 !important;
    pointer-events: inherit !important;
}

section.ship-type {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    align-items: center;
    padding: 40px;
    width: 100%;
    .ship-label {
        margin-bottom: 1rem;
    }
    .layout {
        margin-bottom: 3rem;
        display: grid;
        grid-gap: 36px;
        max-width: 1200px;
        width: 100%;
    }

    .card {
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
        border-radius: 15px;
        cursor: pointer;
        position: relative;
        padding: 0;
        height: 12rem;
        transition: transform 0.35s;
        padding: 16px;

        .head {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;
            height: 45px;
            padding-right: 20px;
            gap: 24px;
            i {
                font-size: 30px;
            }
            label {
                cursor: pointer !important;
                position: relative;
                text-align: left;
                text-transform: inherit;
                font-size: 20px;
                color: #343434;
                font-weight: 500;
                position: relative;
            }
        }
        .body {
			margin-top: 30px;
			display: flex;
			flex-direction: row nowrap;

            span {
				position: absolute;
				left: 22px;
				bottom: 16px;
                font-style: italic;
                color: #00ba5d;
                font-size: 24px;
                font-weight: bold;
            }
        }

        span {
            position: absolute;
            width: 70%;
            left: 65px;
            font-size: 18px;
            i {
                font-size: 22px;
                text-transform: uppercase;
                color: #00ba5d;
                font-weight: bold;
            }
        }
        p {
            z-index: 4;
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            margin: 0;
            label {
                display: flex;
                flex-direction: row;
                position: absolute;
                top: 0;
                height: 100%;
                width: 100%;

                span {
                    top: 15px;
                    left: 20px;
                    color: #343434;
                    font-weight: 500;
                    font-size: 20px;
                    padding: 1px 0 0 45px;
                    width: 0;
                    height: 0;

                    &:before {
                        border: 2px solid #fb5656;
                        width: 23px;
                        height: 23px;
                    }
                    &:after {
                        background-color: #fb5656;
                        width: 23px;
                        height: 23px;
                        border: none;
                    }
                }
            }
        }

        h5 {
            font-size: 60px;
            position: absolute;
            top: 30px;
            right: 70px;
            width: 10px;
        }

        img {
            position: absolute;
            top: 4rem;
            right: 1.5rem;
            height: 4.5rem;
        }
    }

    .card.checked {
        box-shadow: 0 0 18px rgba(0, 0, 0, 0.4);
        transform: scale(1.05);
        transition: transform 0.35s;
    }

    .address {
        padding-right: 2rem;

        h4 {
            text-align: left;
            margin-bottom: 2rem;
            font-size: 20px;
            font-weight: 500;
        }
    }
    .delivery-to-address {
        z-index: 8;
        max-width: 400px;
    }
}

@media screen and (max-width: 900px) {
    .layout {
        grid-template-columns: repeat(auto-fit, 1fr);
    }
}
@media screen and (min-width: 901px) {
    .layout {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
}
</style>
