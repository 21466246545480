<template>
	<div class="payment-type">
		<div class="page-subtitle center">Выберите тип оплаты</div>
		<div class="page-description center">
			Мы работаем по 50%-ной или полной предоплате
		</div>
		<div class="option-wrapper">
			<div
				class="half-payment payment-option"
				:class="{ checked: value === '50' }"
				@click="onOptionInput('50')"
			>
				<label>50% предоплата</label>
				<input type="radio" value="50" :checked="value === '50'" />
			</div>

			<div
				class="full-payment payment-option"
				:class="{ checked: value === '100' }"
				@click="onOptionInput('100')"
			>
				<label>100% предоплата</label>
				<input type="radio" value="100" :checked="value === '100'" />
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'PaymentOptions',
	props: {
		value: {
			type: String,
			required: false,
			default: '50',
		},
		calculatedPrice: {
			type: Number,
			required: false,
			default: 0,
		},
	},
	methods: {
		onOptionInput(value) {
			this.$emit('input', value);
		},
	},

};
</script>

<style lang="less" scoped>
[type='radio'] {
	cursor: pointer !important;
	height: 30px;
	width: 30px;
}

[type='radio']:not(:checked),
[type='radio']:checked {
	position: relative !important;
	opacity: 1 !important;
	pointer-events: inherit !important;
}

.page-description {
	margin-top: 26px;
}

.payment-type {
	position: relative;
	margin-top: 36px;
	width: 100%;
	margin-bottom: 10rem;
	.option-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
		align-content: center;
		margin-top: 72px;
		gap: 24px;
		.payment-option {
			cursor: pointer;
			transition: all 0.15s;
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			justify-content: flex-start;
			height: 45px;
			padding-right: 20px;
			gap: 20px;

			label {
				cursor: pointer !important;
				position: relative;
				text-align: left;
				text-transform: inherit;
				font-size: 24px;
				color: #343434;
				font-weight: 500;
				position: relative;
			}

			i {
				top: 15px;
				position: absolute;
				font-size: 30px;
			}
		}
		.payment-option.checked {
			transform: scale(1.1);
		}
	}
}
</style>
