<template>
    <div class="main" :class="{ success: successBuy }">
        <transition name="fade">
            <FullScreenLoader v-if="loading" />

            <div v-else-if="successBuy" class="success-banner">
                <div class="content">
                    <i class="material-icons">done_outline</i>
                    <span> Заказ успешно оформлен!</span>
                    <p>Информация отправлена вам на почту</p>
                </div>
            </div>

            <div v-else class="checkout">
                <div class="checkout-content row">
                    <div class="design-box"></div>
                    <div class="checkout-form col s12 m6 l5 xl4">
                        <div class="page-subtitle">Кому подарок?</div>

                        <form @submit.prevent="submitHandler">
                            <div class="form-content">
                                <div
                                    class="input-field phone"
                                    :class="{
                                        invalid:
                                            $v.form.phone.$dirty &&
                                            !$v.form.phone.required,
                                    }"
                                >
                                    <input
                                        ref="phone"
                                        v-mask="'+7(###)###-##-##'"
                                        id="phone"
                                        type="tel"
                                        v-model.trim="form.phone"
                                    />

                                    <label for="phone"
                                        >Ваш номер телефона*
                                    </label>

                                    <small
                                        class="helper-text invalid"
                                        v-if="
                                            $v.form.phone.$dirty &&
                                            !$v.form.phone.required
                                        "
                                    >
                                        Введите телефон
                                    </small>

                                    <small
                                        class="helper-text invalid"
                                        v-else-if="
                                            $v.form.phone.$dirty &&
                                            $v.form.phone.$model.length < 11
                                        "
                                    >
                                        Введите верный номер
                                    </small>
                                </div>

                                <div
                                    class="input-field"
                                    :class="{
                                        invalid:
                                            ($v.form.email.$dirty &&
                                                !$v.form.email.required) ||
                                            ($v.form.email.$dirty &&
                                                !$v.form.email.email),
                                    }"
                                >
                                    <input
                                        id="email"
                                        type="text"
                                        v-model.trim="form.email"
                                        :class="{
                                            invalid:
                                                ($v.form.email.$dirty &&
                                                    !$v.form.email.required) ||
                                                ($v.form.email.$dirty &&
                                                    !$v.form.email.email),
                                        }"
                                    />
                                    <label for="email">Почта* </label>

                                    <small
                                        class="helper-text invalid"
                                        v-if="
                                            $v.form.email.$dirty &&
                                            !$v.form.email.required
                                        "
                                    >
                                        Введите Email
                                    </small>

                                    <small
                                        class="helper-text invalid"
                                        v-else-if="
                                            $v.form.email.$dirty &&
                                            !$v.form.email.email
                                        "
                                    >
                                        Введите верный Email
                                    </small>
                                </div>

                                <div
                                    class="input-field"
                                    :class="{
                                        invalid:
                                            $v.form.name.$dirty &&
                                            !$v.form.name.required,
                                    }"
                                >
                                    <input
                                        id="name"
                                        type="text"
                                        v-model.trim="form.name"
                                        :class="{
                                            invalid:
                                                $v.form.name.$dirty &&
                                                !$v.form.name.required,
                                        }"
                                    />
                                    <label for="name">Ваше имя*</label>

                                    <small
                                        class="helper-text invalid"
                                        v-if="
                                            $v.form.name.$dirty &&
                                            !$v.form.name.required
                                        "
                                    >
                                        Введите имя
                                    </small>
                                </div>

                                <div class="input-field text-area">
                                    <textarea
                                        id="comment"
                                        type="text"
                                        v-model.trim="form.comment"
                                        placeholder="Время и точный адрес, какой шоколад лучше использовать и тд"
                                    >
                                    </textarea>
                                    <label for="comment">
                                        Пожелания к заказу
                                    </label>
                                </div>
                            </div>
                            <div class="result-container">
                                <transition name="fade">
                                    <div
                                        v-if="formValid"
                                        class="valid-form__button"
                                    >
                                        <div class="title">
                                            <span class="main-title">
                                                Отправить заказ!
                                            </span>
                                            <span class="price">
                                                {{
                                                    orderPriceByDelivery
                                                        | currency
                                                }}
                                            </span>
                                        </div>

                                        <button
                                            class="btn waves-effect waves-light"
                                            type="submit"
                                        >
                                            <i class="material-icons right">
                                                send
                                            </i>
                                        </button>
                                    </div>

                                    <div v-else class="invalid-form__button">
                                        <div class="title">
                                            <span> Заполните все поля </span>
                                        </div>
                                        <i class="material-icons">
                                            error_outline
                                        </i>
                                    </div>
                                </transition>
                            </div>
                        </form>
                    </div>

                    <div
                        class="cart-preview col s11 m6 l5 xl5 offset-l2 offset-xl2"
                    >
                        <contact-options v-model="form.connect" />
                    </div>
                </div>
                <!-- <div class="payment">
                    <payment-options
                        v-model="form.paymentType"
                        :orderPrice="orderPrice"
                    />
                </div> -->

                <section class="date row">
                    <div class="page-subtitle center">Дата доставки</div>
                    <div class="input-field">
                        <DatePicker
                            locale="ru"
                            v-model="form.date"
                            :min-date="new Date()"
                            color="red"
                        ></DatePicker>
                        <small
                            class="helper-text date-helper-invalid invalid"
                            v-if="!$v.form.date.required"
                        >
                            Укажите дату доставки!
                        </small>
                    </div>
                </section>
                <ship-options
                    v-model="form.deliveryType"
                    :address.sync="form.address"
                    :deliveryPrice="deliveryPrice"
                />
            </div>
        </transition>
    </div>
</template>

<script>
import Calendar from "v-calendar/lib/components/calendar.umd";
import DatePicker from "v-calendar/lib/components/date-picker.umd";

import { email, required, minLength } from "vuelidate/lib/validators";
import ContactOptions from "@/components/app/ContactOptions";
import PaymentOptions from "@/components/app/PaymentOptions";
import ShipOptions from "@/components/app/ShipOptions";

export default {
    name: "checkout",
    components: {
        DatePicker,
        Calendar,
        ContactOptions,
        PaymentOptions,
        ShipOptions,
    },
    validations: {
        form: {
            phone: {
                required,
                minLength: minLength(10),
            },
            email: {
                email,
                required,
            },
            name: { required },
            date: { required },
        },
    },
    data: () => ({
        deliveryPrice: 490,
        form: {
            phone: "",
            email: "",
            name: "",
            address: "",
            comment: "",
            pickup: false,
            delivery: true,
            paymentType: "50",
            deliveryType: "delivery",
            connect: "telegram",
            date: new Date(),
        },
        formData: {},
        loading: false,
        successBuy: false,
    }),

    methods: {
        onDayClick(value) {
            this.form.date = value.date;
        },
        async submitHandler() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                try {
                    this.loading = true;
                    window.scroll({
                        top: 0,
                        left: 0,
                    });
                    const formData = {
                        customerInfo: {
                            phone: this.form.phone,
                            email: this.form.email,
                            name: this.form.name,
                            connectMethod: this.form.connect,
                        },
                        orderInfo: {
                            paymentType: this.form.paymentType,
                            productList: this.$store.getters.getCart,
                            cartSum: this.$store.getters.gerCartSum,
                            comment: this.form.comment,
                        },
                        deliveryInfo: {
                            deliveryType: this.form.deliveryType,
                            address: this.form.address,
                            date: this.form.date,
                        },
                    };
                    const res = await this.$store.dispatch(
                        "newOrder",
                        formData
                    );
                    if (res.status === 200) {
                        this.successBuy = true;
                        document.documentElement.style.overflow = "hidden";
                        document.documentElement.style.position = "fixed";
                        setTimeout(() => {
                            document.documentElement.style.overflow = "auto";
                            document.documentElement.style.position =
                                "relative";
                            this.$router.push("/");
                            this.successBuy = false;
                            this.$store.commit("clearCart");
                        }, 2000);
                    }
                } catch (e) {
                    console.error(e);
                    this.$message("Произошла ошибка. Попробуйте позже");
                } finally {
                    this.loading = false;
                }
            } else {
                this.$message("Заполните все поля");
            }
        },
    },

    computed: {
        formValid() {
            return !this.$v.form.$invalid;
        },
        orderPriceByDelivery() {
            return this.deliveryType === "delivery"
                ? this.orderPrice + Number(this.deliveryPrice)
                : this.orderPrice;
        },
        orderPrice() {
            return Number(this.$store.getters.gerCartSum);
        },
    },
    watch: {
        type(val) {
            if (val === "delivery") {
                this.pickup = false;
                this.delivery = true;
            }

            if (val === "pickup") {
                this.pickup = true;
                this.delivery = false;
            }
        },
    },
};
</script>

<style lang="less">
.date-helper-invalid {
    margin-top: 200px;
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}

section.date {
    .page-subtitle {
        text-align: center;
    }
    input {
        font-size: 20px !important;
    }
}

.text-area {
    border: none !important;
    textarea {
        width: 100%;
        outline: none;
        font-size: 18px;
        background: #f3f7f8;
        height: 12rem !important;
        border-radius: 16px;
        padding: 16px;
        resize: none;
    }
}

.input-field {
    width: 100%;
    label {
        background: #f3f7f8;
        letter-spacing: 1px;
        left: 4%;
        top: 1%;
        color: #858585;
        font-size: 20px;
    }

    label.active {
        background-color: white;
        top: -0.1rem;
        padding: 1px 10px 1px 10px;
        z-index: 550;
        border-radius: 8px;
        color: #7b7b7b;
    }

    input,
    textarea {
        width: 100% !important;
        box-sizing: border-box !important;

        position: relative;
        border: 3px solid white !important;
        background: #f3f7f8 !important;
        border-radius: 16px !important;
        box-shadow: inset 3px 3px 7px #ecf0f1, inset -3px -3px 7px #ffffff,
            4px 2px 16px #e0e4e5, 0px 0px 1px #ffffff !important;
        font-weight: 500 !important;
        color: #4b4b4b !important;
        font-size: 16px !important;
        text-align: start;
    }
    input {
        padding: 8px 16px !important;
        height: 54px !important;
    }
    textarea {
        padding: 16px !important;
        height: 120px !important;
    }
}

.invalid {
    small {
        position: absolute;
        left: 4%;
        bottom: 2.7rem;
        padding: 1px 10px 1px 10px;
        width: 90%;
        z-index: 555;
        border-radius: 8px;
        text-transform: lowercase;
        color: #fd5949;

        letter-spacing: 1px;
        font-size: 16px;
        background-color: white;

        &::before {
            position: absolute;
            height: 6px;
            width: 6px;
            background: #fd5949;
            content: " ";
            border-radius: 50px;
            top: 11px;
            right: 10px;
        }
    }
}

.invalid.input-field {
    transition: transform 0.3s;
}

.checkout {
    position: relative;
    padding-bottom: 30vh;
    .checkout-content {
        padding: 6%;

        h5 {
            color: #4b4b4b;
            font-size: 23px;
            font-weight: 500;
        }

        .checkout-form {
            .form-content {
                display: flex;
                flex-direction: column;
                gap: 16px;
            }
        }
    }

    .date {
        max-width: 300px;
        margin-top: 5rem;
        display: flex;
        flex-direction: column;
        margin-bottom: 5rem;
        align-items: center;
        justify-content: center;

        h4 {
            font-size: 28px;
            font-weight: 500;
            text-align: center;
        }
        .vc-popover-content-wrapper {
            z-index: 400 !important;
        }
        .date-container {
            position: relative;
            border-radius: 18px;
            margin-top: 40px;
            margin-bottom: 3rem;
            padding: 0;
            width: 18.7rem;

            small {
                text-align: center;
                position: absolute;
                top: 6rem;
                width: 80%;
                left: 2rem;
                color: #f05747;
                font-size: 17px;
            }

            span {
                border-radius: 18px;
                box-shadow: 10px 10px 36px rgba(18, 21, 25, 0.48),
                    0 0 26px #d8dcdd;
                padding: 20px;
                top: 23px;
                position: relative;
                font-weight: 600 !important;
                font-size: 20px !important;
                background-color: transparent !important;
                input {
                    padding: 20px;
                    border: none;
                    height: 3rem;
                    cursor: none;
                    text-align: center !important;
                }
            }
        }
    }
}
.design-box {
    display: none;
}

@media screen and (max-width: 700px) {
    .checkout {
        .checkout-content {
            h4 {
                color: #4b4b4b;
                margin-top: 5vh;
                text-transform: uppercase;
                font-size: 26px;
                font-weight: 600;
            }
        }
    }
    .success-banner {
        opacity: 0;
        transform: scale(0);
        transition: all 0.2s ease-in-out;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        height: 100%;
        background-color: white;
        align-items: center;
        z-index: 999;
        position: fixed;
        padding: 20vh 10% 10% 10%;

        .content {
            transition: all 1s ease-in-out;
            transform: scale(0);
            opacity: 0;

            display: flex;
            flex-direction: column;
            text-align: center;
            top: 0;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            height: 100%;
            background-color: white;
            align-items: center;

            i {
                transition: translate 0.3s;
                box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
                padding-top: 20px;
                position: relative;
                height: 150px;
                width: 150px;
                font-size: 110px;
                background-color: #00e278;
                border-radius: 100px;
                color: white;
            }
            span {
                margin-top: 40px;
                font-size: 30px;
                font-weight: bold;
            }

            p {
                font-size: 20px;
                font-weight: 500;
            }
        }
    }

    .main.success {
        .success-banner {
            opacity: 1;
            transition: all 0.2s ease-in-out;
            transform: scale(1);
            .content {
                transition: all 0.3s ease-in-out;
                transform: scale(1);
                opacity: 1;
                i {
                    transition: translate 0.3s;
                }
            }
        }
    }
}

.result-container {
    transition: transform 0.3s ease;
    position: fixed;
    padding: 20px;
    width: 100%;
    height: 40px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 600;
    display: flex;
    flex-direction: row nowrap;
    justify-content: flex-end;

    .invalid-form__button {
        background-color: rgba(243, 243, 243, 0.93);
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
		max-width: 400px;
        width: 100%;
        height: auto;
        padding: 16px;
        z-index: 500;
        bottom: 52px;
        height: 50px;
        border-radius: 20px;
        z-index: 300 !important;
		padding-left: 20px ;
        .title {
            color: #969696;
            font-size: 16px;
            font-weight: 500;
        }
        i {
            position: relative;
            color: #fd5949;
            font-size: 30px;
        }
    }

    .valid-form__button {
        position: relative;

        width: 100vw;
        max-width: 350px;
        bottom: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 100%;
        z-index: 600;
        background-color: rgba(237, 237, 237, 0.95);
        height: 90px;
        border-radius: 32px;

        z-index: 500;
        bottom: 140px;
        padding: 6px;
        .title {
            height: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding-left: 24px;
            .main-title {
                color: #969696;
                font-size: 16px;
                font-weight: bold;
                font-style: italic;
                text-transform: uppercase;
            }
            .price {
                color: #6b6b6b;
                font-size: 22px;
                font-weight: bold;
                font-style: italic;
                text-transform: uppercase;
            }
        }
        button {
            background: linear-gradient(
                69.08deg,
                #57b3ff 2.63%,
                #52a7ee 132.25%
            );
            // background-color: #52a7ee;
            box-shadow: -4px 1px 18px rgba(82, 167, 238, 0.7),
                -6px -6px 16px rgba(255, 255, 255, 0.6);
            position: relative;
            height: 100%;
            width: 80px;
            border-radius: 32px;

            i {
                width: 40px;
                height: 35px;
                margin: 0 auto;
                color: white;
                font-weight: bold;
                font-size: 30px;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .result-container {
        height: auto !important;
        padding: 0 !important;
        .valid-form__button {
            height: 80px;
            border-radius: 32px 32px 0 0;
            position: relative !important;
            width: 100vw !important;
            max-width: 100vw !important;
            bottom: 0px !important;
        }
		.invalid-form__button {
			bottom: 0 !important;
			border-radius: 0px !important;
		}
    }
}

@media screen and (min-width: 701px) {
    .checkout {
        position: relative;

        .checkout-content {
            padding: 6%;

            h4 {
                letter-spacing: 3px;
                color: #4b4b4b;
                margin-top: 5vh;
                text-transform: uppercase;
                font-size: 30px;
                font-weight: 600;
            }
        }
    }

    .success-banner {
        opacity: 0;
        transform: scale(0);
        transition: all 0.5s ease-in-out;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        height: 100%;
        background-color: white;
        align-items: center;
        z-index: 999;
        position: fixed;
        padding-top: 20vh;

        .content {
            transition: all 1s ease-in-out;
            transform: scale(0);

            display: flex;
            flex-direction: column;
            text-align: center;
            top: 0;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            height: 100%;
            background-color: white;
            align-items: center;

            i {
                transition-delay: 2.3s;
                transition: translate 0.3s;
                box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
                padding-top: 20px;
                position: relative;
                height: 150px;
                width: 150px;
                font-size: 110px;
                background-color: #00e278;
                border-radius: 100px;
                color: white;
            }
            span {
                margin-top: 40px;
                font-size: 30px;
                font-weight: bold;
            }

            p {
                font-size: 20px;
                font-weight: 500;
            }
        }
    }

    .main.success {
        .success-banner {
            opacity: 1;
            transition: all 0.2s ease-in-out;
            transform: scale(1);
            .content {
                transition: all 0.3s ease-in-out;
                transform: scale(1);
                opacity: 1;
                i {
                    transition: translate 0.3s;
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
}

@media screen and (min-width: 901px) {
}
</style>
